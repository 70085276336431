<template>
  <div class="stacked-list">
    <StackedListItem v-for="(item, index) in items" :key="index" :item="item">
      <template #left="{ item }">
        <slot name="left" :item="item" />
      </template>
      <template #center="{ item }">
        <slot name="center" :item="item" />
      </template>
      <template #right="{ item }">
        <slot name="right" :item="item" />
      </template>
    </StackedListItem>
  </div>
</template>

<script>
import StackedListItem from './StackedListItem.vue';

export default {
  components: {
    StackedListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
