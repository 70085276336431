<template>
  <div class="stacked-list-item">
    <div class="grid grid-cols-3 w-full">
      <div class="justify-self-start">
        <slot name="left" :item="item" />
      </div>
      <div class="justify-self-center">
        <slot name="center" :item="item" />
      </div>
      <div class="justify-self-end">
        <slot name="right" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="postcss" scoped>
.stacked-list-item {
  @apply p-6 bg-white shadow-lg;
}
.stacked-list-item:first-child {
  @apply rounded-t;
}
.stacked-list-item:last-child {
  @apply rounded-b;
}
.stacked-list-item:not(:last-child) {
  @apply border-b border-gray-200;
}
</style>
